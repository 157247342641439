import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth.vue')
  },
  {
    path: '/',
    name: 'Dash',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dash.vue')
  },
  {
    path: '/segment',
    name: 'Segment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Segment.vue')
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoices/InvoicesIndex.vue')
  },
  {
    path: '/invoice/create/:id?',
    name: 'Create Invoice',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoices/CreateInvoice.vue')
  },
  {
    path: '/invoice/update/:id',
    name: 'Update Invoice',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoices/CreateInvoice.vue')
  },


  {
      path: '/clients/create',
      name: 'clients.show',
      component: () => import('../views/clients/ClientsShow')
  },
  {
    path: '/clients/:mode?',
    name: 'clients.index',
    component: () => import('../views/clients/ClientsIndex')
  },
  {
    path: '/clients/:id/edit',
    name: 'clients.show',
    component: () => import('../views/clients/ClientsShow')
  },
  {
      path: '/clients/:id/overview',
      name: 'clients.overview',
      component: () => import('../views/clients/ClientsOverview')
  },
  {
      path: '/projects/create',
      name: 'projects.show',
      component: () => import('../views/projects/ProjectsShow')
  },
  {
    path: '/projects/:mode?',
    name: 'projects.index',
    component: () => import('../views/projects/ProjectsIndex')
  },
  {
    path: '/projects/:id/edit',
    name: 'projects.show',
    component: () => import('../views/projects/ProjectsShow')
  },
  {
    path: '/projects/:id/overview',
    name: 'projects.overview',
    component: () => import('../views/projects/ProjectsOverview')
  },


  {
    path: '/agencies',
    name: 'agencies.index',
    component: () => import('../views/agencies/AgenciesIndex')
  },
  {
    path: '/agencies/create',
    name: 'agencies.show',
    component: () => import('../views/agencies/AgenciesShow')
  },
  {
    path: '/agencies/:id',
    name: 'agencies.show',
    component: () => import('../views/agencies/AgenciesShow')
  },

  {
      path: '/tasks',
      name: 'tasks.index',
      component: () => import('../views/tasks/TasksIndex')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

Vue.filter('currency', function (value) {
    if (typeof value === "number") {
        return value.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
    return "#";
    /*
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency
    });
    return formatter.format(value);
     */
});

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {

  if (!error.response || error.response.status === 401) {
        store.dispatch('logout')
        router.push('/auth')
  }
  return Promise.reject(error)
})

Vue.config.devtools = true
Vue.config.productionTip = false
Vue.use(VueToast);

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
      if(localStorage.getItem('token')){
          this.$store.commit('SET_TOKEN', localStorage.getItem('token'))
          this.$store.dispatch('getClients')
          this.$store.dispatch('getProjects')
      }
  },
}).$mount('#app')

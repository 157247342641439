<template>

	<div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-2 bg-black" style='min-height: 100vh'>

					<div class="sticky-top pt-3 text-white">

						<h6 class="sidebar-heading d-flex text-uppercase text-muted"><span><strong>Marsmedia Timetracker</strong></span></h6>

						<ul class="nav flex-column mt-4">
							<li class="nav-item mt-2">
								<router-link class="nav-link text-white" :to="'/'">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
									     class="feather feather-home" aria-hidden="true">
										<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
										<polyline points="9 22 9 12 15 12 15 22"></polyline>
									</svg>
									Dashboard
								</router-link>
							</li>
							<li class="nav-item mt-2">
								<router-link class="nav-link text-white" :to="'/tasks'">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
									     class="feather feather-file" aria-hidden="true">
										<path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
										<polyline points="13 2 13 9 20 9"></polyline>
									</svg>
									Tasks
								</router-link>
							</li>
							<li class="nav-item mt-2">
								<router-link class="nav-link text-white" :to="'/invoices'">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
									     class="feather feather-file" aria-hidden="true">
										<path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
										<polyline points="13 2 13 9 20 9"></polyline>
									</svg>
									Invoices
								</router-link>
							</li>
							<li class="nav-item mt-2">
								<router-link class="nav-link text-white" :to="'/clients'">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
									     class="feather feather-users" aria-hidden="true">
										<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
										<circle cx="9" cy="7" r="4"></circle>
										<path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
										<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
									</svg>
									Clients
								</router-link>
							</li>
							<li class="nav-item mt-2">
								<router-link class="nav-link text-white" :to="'/projects'">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
									     class="feather feather-layers" aria-hidden="true">
										<polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
										<polyline points="2 17 12 22 22 17"></polyline>
										<polyline points="2 12 12 17 22 12"></polyline>
									</svg>
									Projects
								</router-link>
							</li>
						</ul>

						<hr/>

						<ul class="nav flex-column">
							<li class="nav-item">
								<router-link class="nav-link text-white" :to="'/agencies'">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
									     class="feather feather-layers" aria-hidden="true">
										<polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
										<polyline points="2 17 12 22 22 17"></polyline>
										<polyline points="2 12 12 17 22 12"></polyline>
									</svg>
									Agencies
								</router-link>
							</li>
							<li class="nav-item mt-2">
								<img style="border-radius: 100px; margin: 0 2px 0 16px;" src="http://localhost/marsmedia/timetracker/hhmm/public/letter-avatar/s.png" width="32" />
								Srdan
							</li>
						</ul>

						<hr/>

						<ul class="nav flex-column mt-4 position-fixed">
							<li class="nav-item mt-2">
								<button class="btn btn-light" v-on:click="logout()">Logout</button>
							</li>
						</ul>
					</div>

				</div>
				<div class="col-md-10">
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		drawer: null
	}),
	props: {
		source: String
	},
	methods: {
		logout() {
			this.$store.commit('SET_TOKEN', '')
			this.$router.push('/')
		},
	},
}
</script>
<template>
  <div id="app">
    <component v-bind:is="layout"></component>
  </div>
</template>

<style lang="scss">
#app {
 	font-family: "Satoshi-Regular";
//    font-family: "Figtree", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	background: #f7f7f7;
}
body {
}
.main {
	max-width: 1400px;
	margin: 0 auto;
}
p.description {
	font-size: 14px;
}
.nav svg {
	width: 32px;
	opacity: 0.5;
}
h1, h2, h3, h4, h5 {
    font-family: "Satoshi-Regular";
//	font-family: "Figtree", sans-serif;
}
p {
    font-size: 13px;
    line-height: 18px;
}
.color-swatch {
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 24px;
	margin-right: -3px;
	border: 1px solid rgba(0, 0, 0, 0.15) !important;
	background-clip: padding-box;
}
.color-swatch.small {
	width: 16px;
	height: 16px;
	line-height: 16px;
}
.color-swatch.inactive {
	opacity: 0.66;
}
.color-swatch.active {
	outline: solid 2px #000;
	opacity: 1;
}
.badge-project-name {
    width: 12px;
    height: 12px;
    display: inline-block;
	margin-right: 4px;
    border-radius: 3px;
    vertical-align: middle;
}
.bg-paid {
    background-color: rgba(144, 238, 144, 0.2) !important;
}
.form-control.select-paginator {
    border: solid 1px #111 !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0;
    min-width: 70px;
}
fieldset {
	padding: 20px !important;
	margin: 20px 0 !important;
}
fieldset legend {
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: solid 1px rgba(0,0,0,0.1);
}
.form-group {
	margin: 10px 0;
}
.form-group label {
	display: block;
	font-size: 13px;
	font-weight: bold;
	margin-bottom: 5px;
}
.form-group label span {
	color: firebrick;
	text-transform: uppercase;
	font-size: 8px;
}
</style>

<script>
import AppLayout from './layouts/AppLayout'
import SimpleLayout from './layouts/SimpleLayout'
export default {
  computed: {
    layout () {
      return this.$store.getters.layout
    }
  },
  components: {
    'app-layout': AppLayout,
    'simple-layout': SimpleLayout
    // define as many layouts you want for the application
  }
}
</script>

'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        layout: 'app-layout',
        clients: [],
        projects: [],
    },
    mutations: {
        SET_LAYOUT(state, payload) {
            state.layout = payload
        },
        SET_CLIENTS(state, clients) {
            state.clients = clients
        },
        SET_PROJECTS(state, projects) {
            state.projects = projects
        },
        SET_TOKEN(state, token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            localStorage.setItem('token', token)
            state.token = token
        }
    },
    actions: {
        async getClients({ commit }) {
            const res = await axios.get('api/clients')
            if(res.data && res.data.data) {
                commit('SET_CLIENTS', res.data.data)
            }
        },
        async getProjects({ commit }) {
            const res = await axios.get('api/projects')
            if(res.data && res.data.data) {
                commit('SET_PROJECTS', res.data.data)
            }
        },
        logout({commit}) {
            commit('SET_TOKEN', '')
        }
    },
    modules: {},
    getters: {
        isAuthenticated: state => !!state.token,
      getClient: (state) => (id) => {
        for(let i=0; i<state.clients.length; i++) {
          if(state.clients[i].id==id) {
            return state.clients[i]
          }
        }
        return {}
        return state.clients.find(thing => thing.id == id)
        /*
        console.log(id)
        */
      },

      layout(state) {
            console.log(router)
          return (state.token || localStorage.getItem('token')) ? 'app-layout' : 'simple-layout'
      }
    }
})
